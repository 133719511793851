import React from 'react'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { IconTextBlock } from '../components/icontextblock'
import { INFO_THEME_PURPLE } from '../components/infoblock'
import { ACTION_SIGNUP } from '../constants/actions'

export const CONTENT_INFOBLOCK_UNKONF_WHAT_ANCHOR = 'was-ist-eine-unkonf'

const grid = {
  md: 6,
  lg: 3,
}

export const CONTENT_INFOBLOCK_WHAT_IS_A_UNKONF = {
  title: 'Was ist eine UnKonf?',
  content: (
    <Grid fluid>
      <Row>
        <Col {...grid}>
          <IconTextBlock
            icon={'/icons/Barcamp.svg'}
            content={
              <div>Eine UnKonf funktioniert ähnlich wie ein Barcamp.</div>
            }
          />
        </Col>
        <Col {...grid}>
          <IconTextBlock
            icon={'/icons/Herz.svg'}
            content={
              <div>
                Zu Beginn stimmen die Teilnehmenden über das Programm ab.
              </div>
            }
          />
        </Col>
        <Col {...grid}>
          <IconTextBlock
            icon={'/icons/Sprecher.svg'}
            content={
              <div>
                Jede teilnehmende Person ist potentielle Sprecherin oder
                Sprecher.
              </div>
            }
          />
        </Col>
        <Col {...grid}>
          <IconTextBlock
            icon={'/icons/Wissen.svg'}
            content={
              <div>
                Vorträge, Workshops, Diskussionsrunden oder jegliche andere Form
                des Wissensaustauschs sind denkbar.
              </div>
            }
          />
        </Col>
      </Row>
    </Grid>
  ),
  theme: INFO_THEME_PURPLE,
  action: ACTION_SIGNUP,
  anchorLink: CONTENT_INFOBLOCK_UNKONF_WHAT_ANCHOR,
}
