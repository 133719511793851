import PropTypes from 'prop-types'
import styled from 'styled-components'
import React from 'react'
import { SPEAKONF_WHITE } from '../../constants/color'
import { Navigation } from './navigation'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { withPrefix } from 'gatsby'

const SPEAKONF_LOGO = withPrefix('/logo_v2.svg')

const StHeaderContainer = styled.div`
  background: ${SPEAKONF_WHITE};
  max-width: 1200px;
  max-height: 5rem;
  margin: 0 auto;
`

const StImage = styled.img`
  margin: 0;
  padding: 0;
  max-height: 5rem;
  width: 10rem;
`

const StCol = styled(Col).attrs({ className: 'hidden-xs hidden-sm' })`
  align-self: center;
`

const StRow = styled(Row)`
  max-height: 5rem;
`

const StLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 90px;
`

const GRID_HEADER_LOGO = {
  xs: 12,
  sm: 12,
  md: 3,
  lg: 3,
}
const GRID_HEADER_NAV = {
  md: 9,
  lg: 9,
}

const Header = () => (
  <StHeaderContainer>
    <Grid fluid>
      <StRow>
        <Col {...GRID_HEADER_LOGO}>
          <StLogoContainer>
            <StImage src={SPEAKONF_LOGO} />
          </StLogoContainer>
        </Col>
        <StCol {...GRID_HEADER_NAV}>
          <Navigation />
        </StCol>
      </StRow>
    </Grid>
  </StHeaderContainer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
