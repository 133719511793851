import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'
import { SPEAKONF_WHITE } from '../../constants/color'
import Footer from './footer'

export const MAX_PAGE_WIDTH = 1200
export const MIN_PAGE_WIDTH = 300

const StContent = styled.div`
  max-width: ${MAX_PAGE_WIDTH}px;
  min-width: ${MIN_PAGE_WIDTH}px;
  margin: 0 auto;
  padding-top: 0;
  background: ${SPEAKONF_WHITE};
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <StContent>
          {children}
          <Footer />
        </StContent>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
