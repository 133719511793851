import React from 'react'
import styled from 'styled-components'
import { Row, Col, Grid } from 'react-flexbox-grid'
import { SPEAKONF_PURPLE } from '../../constants/color'
import { COUNTDOWN_ANCHOR } from '../countdown'
import { CONTENT_INFOBLOCK_UNKONF_WHAT_ANCHOR } from '../../content/infoblock_what_is_a_unkonf'
import { CONTENT_INFOBLOCK_UNKONF_WHO_ANCHOR } from '../../content/infoblock_who_can_participate'
import { CONTENT_INFOBLOCK_UNKONF_WHEN_ANCHOR } from '../../content/infoblock_when_where'
import { CONTENT_INFOBLOCK_SPONSOR_ANCHOR } from '../../content/infoblock_our_sponsors'
import { CONTENT_INFOBLOCK_APPRECIATION_ANCHOR } from '../../content/infoblock_visit_appreciation'

const StGrid = styled(Grid)`
  align-items: center;
  width: 100%;
`
const StRow = styled(Row)`
  justify-content: space-between;
  font-size: 0.8rem;
`

const StCol = styled(Col)`
  white-space: nowrap;
`

const StLink = styled.a`
  text-decoration: none;
  color: ${SPEAKONF_PURPLE};
`

export const Navigation = () => {
  return (
    <StGrid fluid>
      <StRow>
        <StCol>
          <StLink href={`#${COUNTDOWN_ANCHOR}`}>Countdown</StLink>
        </StCol>
        <StCol>
          <StLink href={`#${CONTENT_INFOBLOCK_UNKONF_WHAT_ANCHOR}`}>
            Info
          </StLink>
        </StCol>
        <StCol>
          <StLink href={`#${CONTENT_INFOBLOCK_UNKONF_WHO_ANCHOR}`}>
            Für wen?
          </StLink>
        </StCol>
        <StCol>
          <StLink href={`#${CONTENT_INFOBLOCK_UNKONF_WHEN_ANCHOR}`}>
            Wann & Wo?
          </StLink>
        </StCol>
        <StCol>
          <StLink href={`#${CONTENT_INFOBLOCK_SPONSOR_ANCHOR}`}>
            Sponsoren
          </StLink>
        </StCol>
        <StCol>
          <StLink href={`#${CONTENT_INFOBLOCK_APPRECIATION_ANCHOR}`}>
            Anmeldung
          </StLink>
        </StCol>
      </StRow>
    </StGrid>
  )
}
