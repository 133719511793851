import React from 'react'
import { INFO_THEME_GREY, INFO_THEME_PURPLE } from '../components/infoblock'
import styled from 'styled-components'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { StParagraph } from '../components/elements/paragraph'
import { withPrefix } from 'gatsby'

const StGrid = styled(Grid)`
  height: 100%;
`

const WHEN_AND_WHERE_ICON_COL = {
  xs: 2,
  sm: 2,
  md: 2,
  lg: 2,
}

const WHEN_AND_WHERE_TEXT_COL = {
  xs: 10,
  sm: 10,
  md: 10,
  lg: 10,
}

const whenIcon = withPrefix('/icons/Kalender.svg')
const whereIcon = withPrefix('/icons/Ort.svg')

const StRow = styled(Row)`
  align-items: center;
`

const CONTENT_INFOBLOCK_UNKONF_WHEN_CONTENT = (
  <StGrid fluid>
    <StRow>
      <Col {...WHEN_AND_WHERE_ICON_COL}>
        <img alt="Kalendar icon" src={whenIcon} style={{ width: '2rem' }} />
      </Col>
      <Col {...WHEN_AND_WHERE_TEXT_COL}>
        <StParagraph>
          Montag, 21. Juni 2021 <br />
          16:00 bis 20:00 Uhr
        </StParagraph>
      </Col>
    </StRow>
  </StGrid>
)

const CONTENT_INFOBLOCK_UNKONF_WHERE_CONTENT = (
  <StGrid fluid>
    <Row>
      <Col {...WHEN_AND_WHERE_ICON_COL}>
        <img alt="Globus icon" src={whereIcon} style={{ maxWidth: 'initial', width: '2rem' }} />
      </Col>
      <Col {...WHEN_AND_WHERE_TEXT_COL}>
        <StParagraph>
          Die SpeaKonf ist eine Remote-Veranstaltung.
        </StParagraph>
      </Col>
    </Row>
  </StGrid>
)

export const CONTENT_INFOBLOCK_UNKONF_WHEN_ANCHOR = 'wann'
export const CONTENT_INFOBLOCK_UNKONF_WHEN = {
  title: 'Wann?',
  content: CONTENT_INFOBLOCK_UNKONF_WHEN_CONTENT,
  theme: INFO_THEME_PURPLE,
  action: { text: 'Kalendereintrag', to: 'calendar.ics' },
  anchorLink: CONTENT_INFOBLOCK_UNKONF_WHEN_ANCHOR,
}
export const CONTENT_INFOBLOCK_UNKONF_WHERE_ANCHOR = 'wo'

export const CONTENT_INFOBLOCK_UNKONF_WHERE = {
  title: 'Wo?',
  content: CONTENT_INFOBLOCK_UNKONF_WHERE_CONTENT,
  theme: INFO_THEME_GREY,
  action: { text: 'Konferenzseite folgt', to: '#' },
  anchorLink: CONTENT_INFOBLOCK_UNKONF_WHERE_ANCHOR,
}
