import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  SPEAKONF_BLACK,
  SPEAKONF_PURPLE,
  SPEAKONF_RED,
  SPEAKONF_WHITE,
} from '../constants/color'

export const BUTTON_THEME_PURPLE = {
  color: SPEAKONF_WHITE,
  backgroundColor: SPEAKONF_PURPLE,
}
export const BUTTON_THEME_RED = {
  color: SPEAKONF_WHITE,
  backgroundColor: SPEAKONF_RED,
}
export const BUTTON_THEME_WHITE_PURPLE = {
  color: SPEAKONF_PURPLE,
  backgroundColor: SPEAKONF_WHITE,
}

export const BUTTON_THEME_WHITE_BLACK = {
  color: SPEAKONF_BLACK,
  backgroundColor: SPEAKONF_WHITE,
}

const StButton = styled.a`
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.color};
  padding: 0.3em 3em;
  text-decoration: none;
`

export const Button = props => {
  const { to, children, theme } = props

  //TODO: replace a by GatsbyLink?
  return (
    <StButton theme={theme} href={to}>
      {children}
    </StButton>
  )
}

Button.defaultProps = {
  theme: BUTTON_THEME_PURPLE,
}

Button.propTypes = {
  theme: PropTypes.oneOf([
    BUTTON_THEME_PURPLE,
    BUTTON_THEME_RED,
    BUTTON_THEME_WHITE_PURPLE,
    BUTTON_THEME_WHITE_BLACK,
  ]),
  to: PropTypes.string.isRequired,
}
