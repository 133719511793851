import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  SPEAKONF_BLACK,
  SPEAKONF_COUNTDOWN_TEXT,
  SPEAKONF_LIGHTGREY,
  SPEAKONF_PINK,
  SPEAKONF_PURPLE,
  SPEAKONF_RED,
  SPEAKONF_WHITE,
} from '../constants/color'
import {
  Button,
  BUTTON_THEME_PURPLE,
  BUTTON_THEME_RED,
  BUTTON_THEME_WHITE_BLACK,
  BUTTON_THEME_WHITE_PURPLE,
} from './button'
import { withPrefix } from 'gatsby'

const kidsImage = withPrefix('/kids.jpg')
const heroImage = withPrefix('/heroimage.jpg')

export const INFO_THEME_PURPLE = {
  backgroundColor: SPEAKONF_PURPLE,
  color: SPEAKONF_WHITE,
  titleColor: SPEAKONF_WHITE,
  buttonTheme: BUTTON_THEME_WHITE_PURPLE,
}

export const INFO_THEME_PINK = {
  backgroundColor: SPEAKONF_PINK,
  color: SPEAKONF_BLACK,
  titleColor: SPEAKONF_RED,
  buttonTheme: BUTTON_THEME_RED,
}

export const INFO_THEME_GREY = {
  backgroundColor: SPEAKONF_LIGHTGREY,
  color: SPEAKONF_BLACK,
  titleColor: SPEAKONF_BLACK,
  buttonTheme: BUTTON_THEME_PURPLE,
}

export const INFO_THEME_WHITE = {
  backgroundColor: SPEAKONF_WHITE,
  color: SPEAKONF_BLACK,
  titleColor: SPEAKONF_BLACK,
  buttonTheme: BUTTON_THEME_PURPLE,
}

export const INFO_THEME_COUNTDOWN = {
  backgroundColor: SPEAKONF_PINK,
  color: SPEAKONF_COUNTDOWN_TEXT,
  titleColor: SPEAKONF_BLACK,
  buttonTheme: BUTTON_THEME_PURPLE,
}

export const INFO_THEME_IMAGE_KIDS = {
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundColor: 'transparent',
  background: `url(${kidsImage})`,
  color: SPEAKONF_WHITE,
  titleColor: SPEAKONF_WHITE,
  buttonTheme: BUTTON_THEME_WHITE_BLACK,
  padding: '3rem',
}

export const INFO_THEME_IMAGE_BEAMER = {
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundColor: 'transparent',
  background: `url(${heroImage}) no-repeat bottom right`,
  color: SPEAKONF_WHITE,
  titleColor: SPEAKONF_WHITE,
  buttonTheme: BUTTON_THEME_WHITE_BLACK,
  padding: '3rem',
  additionalMdRules: `
  @media (max-width: 575px) {
    background-position-x: -750px;
  }
  `,
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;
  background: ${props =>
    props.theme.background ? props.theme.background : 'none'};
  background-color: ${props => props.theme.backgroundColor};
  background-repeat: ${props =>
    props.theme.backgroundRepeat ? props.theme.backgroundRepeat : 'initial'};
  background-size: ${props =>
    props.theme.backgroundSize ? props.theme.backgroundSize : 'initial'};

  width: ${props => props.width};
  color: ${props => props.theme.color};
  justify-content: ${props => props.justifyContent || 'space-between'};
  height: 100%;
  ${props => props.additionalMdRules}
`

const StTitle = styled.h1`
  color: ${props => props.theme.titleColor};
  font-weight: ${props => (props.isHeroImage ? '900' : '500')};
  font-size: ${props => (props.isHeroImage ? '4.25rem' : '3.25rem')};
  text-align: center;
  margin-bottom: 0;
`

const StContent = styled.div`
  margin: ${props => (props.disableMargin ? 'initial' : '4rem 0')};
  max-width: ${props => (props.isHeroImage ? '35rem' : 'unset')};
  font-size: ${props => (props.isHeroImage ? '1.3rem' : '1.2rem')};
  line-height: ${props => (props.isHeroImage ? '1.5rem' : '1.4rem')};
  text-align: ${props => (props.isHeroImage ? 'center' : 'initial')};
`

/*
  TODO: * style button
        * validate if all buttons are 'links', if so add href-handling
 */

export const InfoBlock = props => {
  const {
    title,
    width,
    content,
    theme,
    action,
    anchorLink,
    isHeroImage,
    justifyContent,
  } = props

  const button =
    action !== null ? (
      <Button theme={theme.buttonTheme} to={action.to}>
        {action.text}
      </Button>
    ) : null
  return (
    <StContainer
      width={width}
      theme={theme}
      id={anchorLink}
      justifyContent={justifyContent}
      additionalMdRules={theme.additionalMdRules}
    >
      <StTitle isHeroImage={isHeroImage} theme={theme}>
        {title}
      </StTitle>
      <StContent
        isHeroImage={isHeroImage}
        disableMargin={props.disableMargin}
      >
        {content}
      </StContent>
      {/*<div style={{ height: '3rem', width: '1px' }} />*/}
      {button}
    </StContainer>
  )
}

InfoBlock.defaultProps = {
  width: '100%',
  theme: INFO_THEME_PURPLE,
  isHeroImage: false,
}

InfoBlock.propTpyes = {
  disableMargin: PropTypes.bool,
  bgColor: PropTypes.oneOf([
    SPEAKONF_WHITE,
    SPEAKONF_PINK,
    SPEAKONF_PURPLE,
    SPEAKONF_RED,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  width: PropTypes.string,
  justifyContent: PropTypes.string,
  theme: PropTypes.oneOf([INFO_THEME_PURPLE, INFO_THEME_PINK, INFO_THEME_GREY]),
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  anchorLink: PropTypes.string,
  isHeroImage: PropTypes.bool,
}
