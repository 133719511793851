import React from 'react'
import PropTypes from 'prop-types'
import { withPrefix } from '../../.cache/gatsby-browser-entry'
import styled from 'styled-components'

const StContainer = styled.div`
  margin-bottom: 2rem;
`

const StImg = styled.img`
  height: 4rem;
  margin-bottom: 0.5rem;
`

export const IconTextBlock = props => {
  const icon = withPrefix(props.icon)

  return (
    <StContainer>
      <div>
        <StImg src={icon} />
      </div>
      <div>{props.content}</div>
    </StContainer>
  )
}

IconTextBlock.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
}
