import { Col, Grid, Row } from 'react-flexbox-grid'
import { IconTextBlock } from '../components/icontextblock'
import { INFO_THEME_PINK } from '../components/infoblock'
import React from 'react'
import { ACTION_SIGNUP } from '../constants/actions'

const grid = {
  md: 6,
  lg: 4,
}

export const CONTENT_INFOBLOCK_UNKONF_WHO_ANCHOR = 'wer-darf-mitmachen'

export const CONTENT_INFOBLOCK_UNKONF_WHO = {
  title: 'Wer darf mitmachen?',
  content: (
    <Grid fluid>
      <Row>
        <Col {...grid}>
          <IconTextBlock
            icon={'/icons/Hase.svg'}
            content={
              <div>
                Egal ob alter Konferenzhase oder Neuling auf den Bühnen dieser
                Welt, jeder Teilnehmer ist willkommen!
              </div>
            }
          />
        </Col>
        <Col {...grid}>
          <IconTextBlock
            icon={'/icons/Erfahrungen.svg'}
            content={
              <div>
                Dein Erfahrungsstand spielt keine Rolle. Es ist nicht wichtig,
                wie viele Jahre du bereits programmierst.
              </div>
            }
          />
        </Col>
        <Col {...grid}>
          <IconTextBlock
            icon={'/icons/Fachgebiete.svg'}
            content={
              <div>
                Wir kommen aus der IT-Branche, freuen uns jedoch auch über
                Teilnehmer/ innen anderer Fachgebiete.
              </div>
            }
          />
        </Col>
      </Row>
    </Grid>
  ),
  theme: INFO_THEME_PINK,
  action: ACTION_SIGNUP,
  anchorLink: CONTENT_INFOBLOCK_UNKONF_WHO_ANCHOR,
}
