import React from 'react'
import { INFO_THEME_IMAGE_KIDS } from '../components/infoblock'

const title = `Wir freuen uns auf deinen Besuch!`
const myStyle = {
  backgroundColor: 'white',
  padding: '10px'
}
const content = (
  <div>
    <p>Melde dich jetzt kostenlos an und nimm an der kommenden UnKonf teil!</p>
    <p style={myStyle}><iframe src="https://registration.jug-da.de/registration?eventId=2021-06-21&limit=100&opensBeforeInMonths=2&hideVideoRecording=true" frameBorder="0" width="100%" height="700" marginHeight="0" marginWidth="20"></iframe></p>
  </div>
)
const action = null

export const CONTENT_INFOBLOCK_APPRECIATION_ANCHOR = 'anmeldung'
export const CONTENT_INFOBLOCK_APPRECIATION = {
  title,
  content,
  action,
  theme: INFO_THEME_IMAGE_KIDS,
  anchorLink: CONTENT_INFOBLOCK_APPRECIATION_ANCHOR,
}
