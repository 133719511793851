import React from 'react'
import styled from 'styled-components'
import { Col, Grid, Row } from 'react-flexbox-grid'
import moment from 'moment-timezone'

const StCountDownContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const VSpacer = styled(Col).attrs({
  xs: 12,
  className: 'hidden-md hidden-lg hidden-xl',
})`
  height: 20px;
`
const StCountDownContainerField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StCenteredRow = styled(Row)`
  justify-content: center;
`
const StExpiredText = styled.p`
  font-size: 3rem;
  font-weight: normal;
`

const CountDownValue = styled.span`
  font-size: 3.5rem;
  line-height: 3.5rem;
  font-weight: bold;
`
const CountDownDescription = styled.span`
  font-size: 1.5rem;
`
const StGrid = styled(Grid)``

const countDownFieldGrid = {
  xs: 9,
  sm: 6,
  md: 3,
  lg: 2,
}

const SECONDS_IN_MS = 1000
const MINUTES_IN_SECONDS = 60
const HOURS_IN_MINUTES = 60
const DAYS_IN_HOURS = 24

export const COUNTDOWN_ANCHOR = 'countdown'

export default class CountDown extends React.Component {
  constructor(props) {
    super(props)
    this.getTimeLeft = this.getTimeLeft.bind(this)

    this.then = moment.tz('2021-06-21 16:00:00', 'Europe/Berlin')
    const {
      delta,
      daysLeft,
      hoursLeft,
      minutesLeft,
      secondsLeft,
    } = this.getTimeLeft()

    if (delta > 0) {
      this.countDownTimer = setInterval(this.getTimeLeft, 1000)
    }

    this.state = {
      expired: delta <= 0,
      daysLeft,
      hoursLeft,
      minutesLeft,
      secondsLeft,
    }
  }

  getTimeLeft() {
    let now = moment().tz('Europe/Berlin')

    const delta = this.then - now
    let daysLeft = 0,
      hoursLeft = 0,
      minutesLeft = 0,
      secondsLeft = 0

    if (delta > 0) {
      const seconds = delta / SECONDS_IN_MS
      const minutes = seconds / MINUTES_IN_SECONDS
      const hours = minutes / HOURS_IN_MINUTES
      const days = hours / DAYS_IN_HOURS

      secondsLeft = parseInt(seconds % 60)
      minutesLeft = parseInt(minutes % 60)
      hoursLeft = parseInt(hours % 24)
      daysLeft = Math.floor(days)
    }
    this.setState({
      expired: delta <= 0,
      daysLeft,
      hoursLeft,
      minutesLeft,
      secondsLeft,
    })
    return {
      delta,
      daysLeft,
      hoursLeft,
      minutesLeft,
      secondsLeft,
    }
  }

  componentWillUnmount() {
    this.countDownTimer && clearInterval(this.countDownTimer)
  }

  render() {
    const countDown = (
      <>
        <Col {...countDownFieldGrid}>
          <StCountDownContainer id={COUNTDOWN_ANCHOR}>
            <StCountDownContainerField>
              <CountDownValue>{this.state.daysLeft}</CountDownValue>
              <CountDownDescription>Tage</CountDownDescription>
            </StCountDownContainerField>
          </StCountDownContainer>
        </Col>
        <VSpacer />
        <Col {...countDownFieldGrid}>
          <StCountDownContainer>
            <StCountDownContainerField>
              <CountDownValue>{this.state.hoursLeft}</CountDownValue>
              <CountDownDescription>Stunden</CountDownDescription>
            </StCountDownContainerField>
          </StCountDownContainer>
        </Col>
        <VSpacer />
        <Col {...countDownFieldGrid}>
          <StCountDownContainer>
            <StCountDownContainerField>
              <CountDownValue>{this.state.minutesLeft}</CountDownValue>
              <CountDownDescription>Minuten</CountDownDescription>
            </StCountDownContainerField>
          </StCountDownContainer>
        </Col>
        <VSpacer />
        <Col {...countDownFieldGrid}>
          <StCountDownContainer>
            <StCountDownContainerField>
              <CountDownValue>{this.state.secondsLeft}</CountDownValue>
              <CountDownDescription>Sekunden</CountDownDescription>
            </StCountDownContainerField>
          </StCountDownContainer>
        </Col>
      </>
    )
    const content = this.state.expired ? (
      <StExpiredText>Juhuuu, es ist SpeaKonf!</StExpiredText>
    ) : (
      countDown
    )
    return (
      <StGrid>
        <StCenteredRow>{content}</StCenteredRow>
      </StGrid>
    )
  }
}
